.carousel__dot-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel__dot {
  margin: 0 2px;
  transition: all 200ms linear;
  padding: 3px;
}

.carousel__dot span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #cacaca;
  display: block;
}

.carousel__dot.carousel__dot--selected span {
  background-color: #000000;
}
